import { UserAccessSettings } from '../../features/connections/models/feature.model';
import { SubscriptionNames } from '../../features/connections/models/subscriptions';

export interface Customer {
	customerId: string;
	email?: string;
	name?: string;
	phone?: string;
	timeZone?: string;
}

export interface CurrentCustomer {
	accountCount: number;
	customerId: string;
	enterprise: boolean;
	name: string;
	subscriptionType: SubscriptionNames;
	waitingToOnboard: boolean;
	freemiumConnectionUsed: boolean;
	settings: UserAccessSettings;
}

export enum SessionInitializationResourceType {
	connection = 'connection',
}

export enum SessionInitializationAction {
	resync = 'resync',
	update = 'update',
	revoke = 'revoke',
}

export interface SessionInitializationParameters {
	resourceType: SessionInitializationResourceType;
	resourceId: string;
	action: SessionInitializationAction;
}

export interface Session {
	id: string;
	customerId: string;
	expiresAt: number | null;
	initializationParameters: SessionInitializationParameters | null;
}
